.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

button {
  font-family: 'Roboto Mono', monospace;
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  font-size: 4em;
  text-shadow: 0px 1px 32px black;
}

p {
  font-style: italic;
  color: white;
  text-shadow: 0px 1px 8px black;
  font-size: 0.9em;
}

a {
  background-color: #dddddd;
  margin: 1rem;
  padding: 1em 2.4em;
  border-radius: 18px;
  box-shadow: 0px 1px 32px rgba(0, 0, 0, 0.74);
  font-weight: bold;
  cursor: pointer;
}